import { graphql } from 'gatsby'
import React, { FC } from 'react'
import Layout from '@/containers/Layout'
import ProjectContainer from '@/containers/ProjectContainer'
import { Project } from 'web/types/custom-graphql-types'
import SEO from '@/components/SEO'

interface ProjectProps {
  data: {
    project: Project
  }
}

const ProjectPage: FC<ProjectProps> = ({ data: { project: SanityProject } }) => (
  <Layout
    title={SanityProject.title}
    _type={SanityProject._type}
    i18n_lang={SanityProject.i18n_lang}
    i18n_refs={SanityProject.i18n_refs}
    i18n_base={SanityProject.i18n_base}
    seoSettings={SanityProject.seoSettings}
  >
    <ProjectContainer project={SanityProject} />
  </Layout>
)

export default ProjectPage

export const Head: FC<ProjectProps> = ({ data: { project: sanityProject } }) => <SEO seoSettings={sanityProject.seoSettings} i18n_lang={sanityProject.i18n_lang} />

export const query = graphql`
  query ($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      _id
      _key
      _type
      title
      keyVisual {
        ...ImageWithPreview
      }
      excerpt
      tags {
        ...tagsData
      }
      seoSettings {
        ...seoSettingsData
      }
      contentModulesProject {
        ...contentModulesProjectData
      }
      i18n_lang
      i18n_refs {
        ... on SanityProject {
          _type
          slug {
            current
          }
          i18n_lang
        }
      }
      i18n_base {
        ... on SanityProject {
          _type
          slug {
            current
          }
          i18n_lang
        }
      }
    }
  }
`
